import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormFieldModel, FormService, NotificationService, WidgetComponent } from '@alfresco/adf-core';
import { Node } from '@alfresco/js-api';
import { map, mergeMap, take } from 'rxjs/operators';
import { EMPTY, forkJoin, from, Observable } from 'rxjs';
import { ProcessService } from '../../../../services/process.service';
import { LegacyContentService } from '../../../../services/legacy-content.service';
import { ContentApiService } from '@alfresco/aca-shared';
import { extractNodeId, wrapNodeId } from '../../../../api/utils/node';
import { fabric } from 'fabric';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { ProcessNewComponent } from '../../../../components/process/process-new/process-new.component';
import { TaskEditComponent } from '../../../task/task-edit/task-edit.component';
import { Store } from '@ngrx/store';
import { getUserProfile } from '@alfresco/aca-shared/store';

@Component({
  selector: 'epm-photo-upload-widget',
  templateUrl: './photo-upload-widget.component.html',
  styleUrls: ['./photo-upload-widget.component.scss'],
  host: {
    '(click)': 'event($event)',
    '(blur)': 'event($event)',
    '(change)': 'event($event)',
    '(focus)': 'event($event)',
    '(focusin)': 'event($event)',
    '(focusout)': 'event($event)',
    '(input)': 'event($event)',
    '(invalid)': 'event($event)',
    '(select)': 'event($event)'
  },
  encapsulation: ViewEncapsulation.None
})
export class PhotoUploadWidgetComponent extends WidgetComponent implements OnInit {
  /**
   * value field is the array of NodeReferences - i.e. ["workspace://SpaceStore/324324"]
   */
  private originalFileId: string;
  isEditable: boolean = true;
  hasFile: boolean;
  multipleOption: string = 'true';
  photos: { url: string; id: string }[] = [];
  photosEditable: boolean[] = [];
  photosRemovable: boolean[] = [];
  savedNodes: Node[] = [];
  /** Uploading indicator */
  isUploading: boolean = false;
  isEditing: boolean = false;
  colors: string[] = ['red', 'green', 'blue', 'black', 'yellow'];
  private currentBrushColor: string = 'black';
  private canvas: any;
  photosData: { url: string; id: string; taskName: string }[] = [];

  constructor(
    formService: FormService,
    private notificationService: NotificationService,
    private processService: ProcessService,
    private legacyContentService: LegacyContentService,
    private contentApi: ContentApiService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private store: Store
  ) {
    super(formService);
  }

  ngOnInit(): void {
    this.route.pathFromRoot.forEach((routeSnapshot) => {
      if (
        routeSnapshot.routeConfig &&
        (routeSnapshot.routeConfig.component === ProcessNewComponent || routeSnapshot.snapshot.routeConfig.component === TaskEditComponent)
      ) {
        // this.userCanEditPhoto(this.field.value);
        this.isEditable = true;
      }
    });

    if (this.field && this.field.value && this.field.value.length > 0) {
      this.hasFile = true;
      this.fixIncompatibilityFromPreviousAndNewForm([]);
    }

    this.route.params.subscribe((params) => {
      if (params['mode'] === 'edit' || params['mode'] === undefined) {
        this.isEditable = true;
      } else {
        this.isEditable = false;
      }
    });
  }

  get photoUrlsDynamic(): { url: string; id: string }[] {
    return this.field.value && this.field.value.length > 0 ? this.photos : [];
  }

  getTaskName(nodeId: string) {
    return this.contentApi
      .getNodeInfo(nodeId)
      .pipe(
        map((node) => {
          return node.properties['epmIssue:photoTask'];
        })
      )
      .toPromise();
  }

  onFileChanged(event: any) {
    const files: File[] = [];
    const filesSaved: Node[] = [];
    for (const file of Array.from<File>(event.target.files)) {
      if (!this.isUploaded(file)) {
        this.resizeImage(file, 600).then((resizedFile) => {
          this.uploadAndSaveFile(resizedFile);
          this.photosEditable.push(true);
          this.photosRemovable.push(true);
        });
      } else {
        this.notificationService.showWarning('FORM.FIELD.FILE_ALREADY_UPLOADED');
      }
    }
    if (files && files.length > 0) {
      this.isUploading = true;
      this.getPhotoParentDirectoryId().subscribe((parentDirId) => {
        // now upload all files to the site photos container

        from(files)
          .pipe(mergeMap((file) => this.uploadRawContent(file, parentDirId)))
          .subscribe(
            (res) => {
              filesSaved.push(res);
            },
            (error) => {
              console.log('Error while uploading a file: ' + error);
              this.isUploading = false;
            },
            () => {
              this.fixIncompatibilityFromPreviousAndNewForm(filesSaved);
              this.hasFile = true;
              this.isUploading = false;
            }
          );
      });
    }
  }

  onFieldChanged(field: FormFieldModel) {
    super.onFieldChanged(field);

    if (field.value) {
      // clear photoUrls
    }
  }

  getIssuePhotoUrl(nodeId: string) {
    return this.contentApi.getContentUrl(nodeId);
  }

  getPhotoOwner(nodeId: string) {
    if (!nodeId) {
      return EMPTY;
    }
    return this.contentApi.getNodeInfo(nodeId).pipe(
      map((node) => {
        return node.createdByUser.id;
      })
    );
  }

  userCanEditPhoto(nodeId: string): Observable<boolean> {
    return forkJoin([this.store.select(getUserProfile).pipe(take(1)), this.getPhotoOwner(nodeId).pipe(take(1))]).pipe(
      map(([profile, ownerId]) => {
        if (!ownerId) {
          return true;
        }
        return profile.id === ownerId;
      })
    );
  }

  allowPreviousFileRemoval(photoUrl: string): boolean {
    // if (this.field.readOnly) {
    //   return false;
    // }

    // const allowDeleteOption = this.field.options.find((e) => e.id === 'allowDelete');
    // if (allowDeleteOption && allowDeleteOption.name === 'true') {
    //   return true;
    // }

    // allow deletion of only now added files

    for (let savedNode of this.savedNodes) {
      if (this.extractNodeIdFromPhotoUrl(photoUrl) === savedNode.id) {
        return true;
      }
    }
    return false;
  }

  private extractNodeIdFromPhotoUrl(photoUrl: string) {
    return photoUrl ? photoUrl.match(/nodes\/(.*)\/content/)[1] : photoUrl;
  }

  removeFile(photoUrl: string) {
    if (this.field) {
      const fileId = this.getIdFromUrl(photoUrl);
      this.removeElementFromList(fileId);
    }
  }

  private getIdFromUrl(photoUrl: string): string {
    const startSubstr = photoUrl.substring(photoUrl.indexOf('/nodes/') + '/nodes/'.length);
    return startSubstr.substring(0, startSubstr.indexOf('/'));
  }

  private isUploaded(file: File): boolean {
    const current: Node[] = this.savedNodes || [];
    return current.some((entry) => entry.name === file.name);
  }

  private uploadRawContent(file: File, parentFolderId: string): Observable<Node> {
    // const opts: any = {
    //   include: ['allowableOperations'],
    //   renditions: 'doclib',
    //   versioningEnabled: true,
    //   overwrite: true,
    //   majorVersion: true,
    //   comment: "",
    //   name: file.name,
    //   autoRename: true,
    //   nodeType: 'epmIssue:photo'
    // }
    // const path: string = "";0
    const opts: any = {
      name: Date.now()
    };
    return this.processService.uploadNewFile(file, parentFolderId, opts);
  }

  private getPhotoParentDirectoryId(): Observable<string> {
    const siteid = this._getSiteIdFromForm();
    if (siteid) {
      return this.legacyContentService.getIssuePhotosContainer(siteid);
    }
    return EMPTY;
  }

  private async fixIncompatibilityFromPreviousAndNewForm(filesSaved: Node[]) {
    this.savedNodes.push(...filesSaved);

    const value: string[] = [];
    if (Array.isArray(this.field.value)) {
      value.push(...this.field.value);
    } else if (this.field.value) {
      const spitedValueArr = this.field.value.split(',');
      spitedValueArr.forEach((e) => {
        value.push(e);
      });
    }
    filesSaved.forEach((fileSaved) => value.push(wrapNodeId(fileSaved.id)));

    this.field.value = value;
    this.field.form.values[this.field.id] = value;

    this.photos = value.map((id) => ({
      url: this.getIssuePhotoUrl(extractNodeId(id)),
      id: extractNodeId(id)
    }));
    this.fillPhotos();
    const photoIds = value.map((id) => extractNodeId(id));

    for (let i in photoIds) {
      this.photosEditable[i] = false;
      this.userCanEditPhoto(photoIds[i])
        .pipe(take(1))
        .subscribe((canEdit) => {
          this.photosRemovable[i] = canEdit;
        });
    }

    this.hasFile = value.length > 0;
  }

  private removeElementFromList(fileId: any) {
    const fieldNodeRef = wrapNodeId(fileId);
    const filteredValues = this.field.value.filter((value) => value !== fieldNodeRef);
    this.savedNodes = this.savedNodes.filter((value) => value.id !== fileId);
    if (filteredValues && filteredValues.length > 0) {
      this.field.value = filteredValues;
      this.field.form.values[this.field.id] = filteredValues;
      this.hasFile = true;
    } else {
      this.field.value = [];
      this.field.form.values[this.field.id] = [];
      this.hasFile = false;
    }
    this.photos = this.field.value.map((id) => ({
      url: this.getIssuePhotoUrl(extractNodeId(id)),
      id: extractNodeId(id)
    }));
    this.fillPhotos();
  }

  private _getSiteIdFromForm() {
    return this.field.form.values['epmWf_dataListWfSiteid'];
  }

  editPhoto(photoUrl: string) {
    this.isEditing = true;
    this.originalFileId = this.getIdFromUrl(photoUrl);
    this.toggleEditingButtons(true);
    this.openImageEditor(photoUrl);
  }

  saveEdit() {
    this.isEditing = false;
    this.toggleEditingButtons(false);
  }

  cancelEdit() {
    this.isEditing = false;
    this.toggleEditingButtons(false);
  }

  changeColor(color: string) {
    this.currentBrushColor = color;
    if (this.canvas) {
      this.canvas.freeDrawingBrush.color = this.currentBrushColor;
    }
  }

  openImageEditor(photoUrl: string) {
    const editorModal = document.createElement('div');
    editorModal.style.position = 'fixed';
    editorModal.style.top = '0';
    editorModal.style.left = '0';
    editorModal.style.width = '100%';
    editorModal.style.height = '100%';
    editorModal.style.backgroundColor = 'rgba(0,0,0,0.5)';
    editorModal.style.display = 'flex';
    editorModal.style.flexDirection = 'column';
    editorModal.style.justifyContent = 'center';
    editorModal.style.alignItems = 'center';
    editorModal.style.zIndex = '1000';

    const buttonContainer = document.createElement('div');
    buttonContainer.className = 'button-container';
    buttonContainer.style.display = 'flex';
    buttonContainer.style.flexDirection = 'row';
    buttonContainer.style.marginBottom = '10px';

    editorModal.appendChild(buttonContainer);

    const canvasContainer = document.createElement('div');
    canvasContainer.style.display = 'flex';
    canvasContainer.style.justifyContent = 'center';
    canvasContainer.style.alignItems = 'center';
    canvasContainer.style.width = '100%';
    canvasContainer.style.maxHeight = '70vh';
    editorModal.appendChild(canvasContainer);

    const editorCanvas = document.createElement('canvas');
    editorCanvas.style.touchAction = 'none';
    canvasContainer.appendChild(editorCanvas);
    document.body.appendChild(editorModal);

    this.canvas = new fabric.Canvas(editorCanvas, {
      isDrawingMode: true
    });

    this.canvas.freeDrawingBrush.width = 4;

    fabric.Image.fromURL(photoUrl, (img) => {
      const maxWidth = window.innerWidth * 0.9;
      const maxHeight = window.innerHeight * 0.8;
      const scale = Math.min(maxWidth / img.width, maxHeight / img.height);

      img.scale(scale);

      this.canvas.setWidth(img.getScaledWidth());
      this.canvas.setHeight(img.getScaledHeight());
      this.canvas.add(img);
      this.canvas.sendToBack(img);
    });

    const saveButton = document.createElement('button');
    this.translate.get('SAVE').subscribe((text: string) => {
      saveButton.innerText = text;
    });
    saveButton.className = 'save-button';
    buttonContainer.appendChild(saveButton);

    const closeButton = document.createElement('button');
    this.translate.get('CLOSE').subscribe((text: string) => {
      closeButton.innerText = text;
    });
    closeButton.className = 'close-button';
    buttonContainer.appendChild(closeButton);

    const colorButtonsContainer = document.createElement('div');
    colorButtonsContainer.className = 'color-buttons';
    document.body.appendChild(colorButtonsContainer);

    this.colors.forEach((color) => {
      const colorButton = document.createElement('button');
      colorButton.className = 'color-button';
      colorButton.style.backgroundColor = color;
      colorButton.addEventListener('click', () => {
        this.changeColor(color);
      });
      colorButtonsContainer.appendChild(colorButton);
    });

    saveButton.addEventListener('click', () => {
      const dataUrl = this.canvas.toDataURL({
        format: 'png',
        quality: 1.0
      });
      this.saveEditedPhoto(dataUrl);
      document.body.removeChild(editorModal);
      document.body.removeChild(colorButtonsContainer);
      this.isEditing = false;
    });

    closeButton.addEventListener('click', () => {
      document.body.removeChild(editorModal);
      document.body.removeChild(colorButtonsContainer);
      this.isEditing = false;
    });

    this.canvas.freeDrawingBrush.color = this.currentBrushColor;
  }

  toggleEditingButtons(show: boolean) {
    const buttonContainer = document.querySelector('.button-container');
    if (buttonContainer) {
      if (show) {
        buttonContainer.classList.remove('display-none');
      } else {
        buttonContainer.classList.add('display-none');
      }
    }

    const colorButtonsContainer = document.querySelector('.color-buttons');
    if (colorButtonsContainer) {
      if (show) {
        colorButtonsContainer.classList.remove('display-none');
      } else {
        colorButtonsContainer.classList.add('display-none');
      }
    }
  }

  saveEditedPhoto(dataUrl: string) {
    const file = this.dataURLtoFile(dataUrl, 'edited-photo.png');
    this.uploadAndReplaceFile(file);
  }

  uploadAndReplaceFile(file: File) {
    this.isUploading = true;
    this.getPhotoParentDirectoryId().subscribe((parentDirId) => {
      this.uploadRawContent(file, parentDirId).subscribe(
        (res) => {
          this.replaceOriginalFileWithEdited(res);
        },
        (error) => {
          console.error('Error while uploading a file:', error);
          this.isUploading = false;
        },
        () => {
          this.isUploading = false;
        }
      );
    });
  }

  replaceOriginalFileWithEdited(newFile: Node) {
    // Ensure the arrays are initialized
    if (!this.savedNodes) this.savedNodes = [];
    if (!this.photos) this.photos = [];
    if (!this.field.value) this.field.value = [];

    // Find the original file in savedNodes and remove it
    const originalFileIndex = this.savedNodes.findIndex((node) => node.id === this.originalFileId);
    if (originalFileIndex !== -1) {
      this.savedNodes.splice(originalFileIndex, 1);
    }

    // Find the original file URL in photoUrls and remove it
    const photoUrlIndex = this.photos
      .map((photo) => photo.url)
      .findIndex((photo) => {
        return photo.includes(this.getIssuePhotoUrl(this.originalFileId));
      });
    if (photoUrlIndex !== -1) {
      this.photos.splice(photoUrlIndex, 1);
    }

    // Find the original file ID in field.value and remove it
    const valueIndex = this.field.value.findIndex((value) => extractNodeId(value) === this.originalFileId);
    if (valueIndex !== -1) {
      this.field.value.splice(valueIndex, 1);
    }

    // Add the new file to savedNodes
    this.savedNodes.push(newFile);

    // Add the new file URL to photoUrls
    this.photos.push({
      url: this.getIssuePhotoUrl(newFile.id),
      id: newFile.id
    });
    this.fillPhotos();

    // Add the new file ID to field.value
    this.field.value.push(wrapNodeId(newFile.id));
    this.field.form.values[this.field.id] = this.field.value;

    this.notificationService.openSnackMessage('Plik został pomyślnie przesłany.', 200);
  }

  dataURLtoFile(dataUrl: string, filename: string): File {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  resizeImage(file: File, maxHeight: number): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: 'image/jpeg',
                lastModified: Date.now()
              });
              resolve(resizedFile);
            } else {
              reject(new Error('Błąd przy tworzeniu bloba z obrazem'));
            }
          }, 'image/jpeg');
        };
        img.onerror = () => reject(new Error('Błąd przy wczytywaniu obrazu'));
        img.src = event.target.result;
      };
      reader.onerror = () => reject(new Error('Błąd przy wczytywaniu pliku'));
      reader.readAsDataURL(file);
    });
  }

  uploadAndSaveFile(file: File) {
    this.isUploading = true;
    this.getPhotoParentDirectoryId().subscribe((parentDirId) => {
      this.uploadRawContent(file, parentDirId).subscribe(
        (res) => {
          this.savedNodes.push(res);
          this.updateFormAfterUpload(res);
        },
        (error) => {
          console.error('Error while uploading a file:', error);
          this.isUploading = false;
        },
        () => {
          this.isUploading = false;
        }
      );
    });
  }

  updateFormAfterUpload(fileNode: Node) {
    this.savedNodes.push(fileNode);
    const value: string[] = Array.isArray(this.field.value) ? [...this.field.value] : [];
    value.push(wrapNodeId(fileNode.id));
    this.field.value = value;
    this.field.form.values[this.field.id] = value;
    this.photos = value.map((id) => ({
      url: this.getIssuePhotoUrl(extractNodeId(id)),
      id: extractNodeId(id)
    }));
    this.hasFile = true;
    this.fillPhotos();

    this.notificationService.openSnackMessage('Plik został pomyślnie przesłany.', 2500);
  }

  fillPhotos() {
    const observables = this.photos.map((photo) => this.contentApi.getNodeInfo(photo.id));
    forkJoin(observables)
      .pipe(take(1))
      .subscribe((nodes) => {
        this.photosData = nodes.map((node) => ({
          url: this.getIssuePhotoUrl(node.id),
          id: node.id,
          taskName: node.properties['epmIssue:photoTask']
        }));
      });
  }
}
