import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormService, WidgetComponent } from '@alfresco/adf-core';
import { FormControl } from '@angular/forms';
import { QueryApiService } from '../../../../services/query-api.service';
import { Observable, of } from 'rxjs';
import { filter, catchError, switchMap, distinctUntilChanged, map, debounceTime, tap } from 'rxjs/operators';
import { Person } from '@alfresco/js-api';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'aca-epm-multiple-people-widget',
  templateUrl: './epm-multiple-people-widget.component.html',
  styleUrls: ['./epm-multiple-people-widget.component.scss'],
  host: {
    '(click)': 'event($event)',
    '(blur)': 'event($event)',
    '(change)': 'event($event)',
    '(focus)': 'event($event)',
    '(focusin)': 'event($event)',
    '(focusout)': 'event($event)',
    '(input)': 'event($event)',
    '(invalid)': 'event($event)',
    '(select)': 'event($event)'
  },
  encapsulation: ViewEncapsulation.None
})
export class EpmMultiplePeopleWidgetComponent extends WidgetComponent implements OnInit {
  searchTerm = new FormControl<string>('');

  selectedPeople: Person[] = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];

  searchTerms$: Observable<any> = this.searchTerm.valueChanges;

  @ViewChild('inputValue') inputValue: ElementRef<HTMLInputElement>;

  users$ = this.searchTerms$.pipe(
    tap((searchInput) => {
      if (typeof searchInput === 'string') {
        this.onItemSelect();
      }
    }),
    debounceTime(500),
    distinctUntilChanged(),
    filter((searchInput: string) => searchInput && searchInput.length > 2),
    switchMap((searchTerm: string) => {
      return this.queryService.findPeople(searchTerm).pipe(catchError(() => of()));
    }),
    map((peopleList: Person[]) => {
      const value = this.getDisplayName(this.getUserFromUsername(peopleList, this.searchTerm.value));
      this.checkUserAndValidateForm(peopleList, value);
      return peopleList;
    })
  );

  constructor(public formService: FormService, private queryService: QueryApiService) {
    super(formService);
  }

  ngOnInit(): void {
    if (this.field) {
      if (this.field.value) {
        this.searchTerm.setValue(this.field.value);
      }
      if (this.field.readOnly) {
        this.searchTerm.disable();
      }
    }
  }

  checkUserAndValidateForm(list: Person[], value: string): void {
    const isValidUser = this.isValidUser(list, value);
    if (isValidUser || value === '') {
      this.field.validationSummary.message = '';
      this.field.validate();
      this.field.form.validateForm();
    } else {
      this.field.validationSummary.message = 'FORM.FIELD.VALIDATOR.INVALID_VALUE';
      this.field.markAsInvalid();
      this.field.form.markAsInvalid();
    }
  }

  isValidUser(users: Person[], name: string): boolean {
    if (users) {
      return !!users.find((user) => {
        return this.getDisplayName(user).toLocaleLowerCase() === name.toLocaleLowerCase();
      });
    }
    return false;
  }

  getDisplayName(model: Person) {
    if (model) {
      const displayName = `${model.firstName || ''} ${model.lastName || ''}`;
      return displayName.trim();
    }
    return '';
  }

  onItemSelect(item?: Person) {
    if (item) {
      // called when users picks a selectbox option
      this.selectedPeople.push(item);
      this.field.value = this.selectedPeople.map((user) => user.id);
      this.inputValue.nativeElement.value = '';
    }
  }

  remove(userId) {
    console.log(userId);
    const index = this.selectedPeople.findIndex((user) => user.id === userId);

    if (index >= 0) {
      this.selectedPeople.splice(index, 1);
      this.field.value = this.selectedPeople.map((u) => u.id);
    }
  }

  private getUserFromUsername(peopleList: Person[], username: string): Person {
    return peopleList.find((u) => username === u.id);
  }
}
