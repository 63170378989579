import { Observable } from 'rxjs';
import { Item } from '../../../../api/models/item/item';
import { RestFormModel } from '../../../../api/models/rest-form-model/rest-form-model';
import { createFormFieldContainerJson } from '../../utils/form';
import { FormValues, TranslationService } from '@alfresco/adf-core';
import { DlWorkflowService } from './dl-workflow.service';
import { Injectable } from '@angular/core';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { DynamicListsDataModel } from '../../../../api/models/epm/dynamiclists.model';
import { ProcessDynamicFormData } from '../../../../services/process-dynamic-form.data';
import { RetrieveUxDataModel } from '../../../../api/models/epm/retrieve-ux-data.model';
import { TaskInstance } from '../../../../api/models/task/task-instance';
import { AttachmentMode } from '../../widgets/attach-file/attach-file-widget-component.component';
import { AppStore } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { LegacyContentService } from '../../../../services/legacy-content.service';
import { StandardWorkflow2FormRenderer } from './standard-workflow-2-form-renderer';
import { DatalistsDataModel } from '../../../../api/models/legacy/datalists-data.model';

/**
 * Issue workflow form Renderer.
 */
@Injectable({
  providedIn: 'root'
})
export class IssueWorkflowFormRenderer extends StandardWorkflow2FormRenderer {
  static key = 'issueWorkflow';
  id = 'issueWorkflow';
  name = 'Issue workflow';
  description = 'Default issue workflow form';

  constructor(
    translation: TranslationService,
    dlWorkflowService: DlWorkflowService,
    processDynamicFormData: ProcessDynamicFormData,
    legacyContentService: LegacyContentService,
    store: Store<AppStore>
  ) {
    super(translation, dlWorkflowService, processDynamicFormData, legacyContentService, store);
  }

  onProcessTypeSelect(processType: DynamicListsDataModel) {
    // add remaining fields
    this.fetchUxData$(processType.ref).subscribe((uxData) => {
      const newFormFields = [
        this.processDynamicFormData.lastFormModelValue.json.fields[0],
        this.jsonBpmWorkflowDescription(null, true, false),
        this.jsonEpmWfDataListWfNode(processType.ref, false, true),
        this.jsonEpmWfDataListWfSiteid(processType.siteid, false, true),
        // this.jsonBpmPriority(null, false, false),
        // this.jsonEpmIssueWfCategory('notApplicable', false, false),
        // this.jsonEpmIssueWfExpectedCompletionDate(null, false, false),
        this.jsonEpmIssueWfPlanLocationLat(null, false, false),
        this.jsonEpmIssueWfPlanLocationLng(null, false, false),
        this.jsonEpmIssueWfPlanRef('', false, false),
        this.jsonEpmIssueWfPhotoRefs(null, false, false, true),
        // this.jsonEpmWfDataListWfDesc(null, false, false),
        ...this.jsonEpmWfDepartment([], false, uxData),
        this.jsonAssocPackageItemsAdded(),
        this.jsonBpmComment(null, false, false)
      ];
      const formValues: FormValues = {
        epmWf_dataListWfType: processType.name
      };
      this.processDynamicFormData.updateFormFieldsModel(newFormFields, formValues);
    });
  }

  onProcessTypeChange(processType: DynamicListsDataModel) {
    const newFormFields = [];
    const formValues: FormValues = {
      epmWf_dataListWfType: processType.name,
      epmWf_dataListWfNode: processType.ref
    };
    this.processDynamicFormData.updateFormFieldsModel(newFormFields, formValues);
  }

  createTaskFormFields(taskInstance: TaskInstance, variables: object, formFields: RestFormModel[], attachmentItems: Item[]): Observable<object[]> {
    const uxData$ = this.dlWorkflowService.retrieveUxData(variables['epmWf_dataListWfNode'], variables['epmWf_dataListWfTaskNode']);
    const formFieldsObj = {};
    formFields.forEach((field) => (formFieldsObj[field.name] = field));
    switch (taskInstance.formResourceKey) {
      case 'epmIssueWf:submit':
        return uxData$.pipe(
          map((uxData) => {
            return this.renderDefaultTaskFormFields(formFieldsObj, variables, attachmentItems, uxData, false).concat([this.jsonNextTransition()]);
          })
        );
      case 'epmIssueWf:formalVerification':
        return this.renderStdWfFormalVerification(uxData$, variables, formFieldsObj, attachmentItems);
      case 'epmIssueWf:additionalOpinion':
        return uxData$.pipe(
          map((uxData) => {
            return [
              ...this.renderDefaultTaskFormFields(formFieldsObj, variables, attachmentItems, uxData, true),
              this.jsonStdWfOutcome(variables['stdWf_outcome'], false, false, [{ id: 'Next', name: 'EPM.FORM.OUTCOME.DONE' }])
            ];
          })
        );

      case 'epmIssueWf:resubmit':
        return this.dlWorkflowService.fetchWfTypes('activiti$issueWorkflow').pipe(
          takeUntil(this.onDestroy$),
          map((model) => {
            const siteId = variables['epmWf_dataListWfSiteid'];

            let processTypes;
            if (siteId && model?.data) {
              processTypes = model?.data.filter((type) => type.siteid === siteId);
            } else {
              processTypes = model?.data;
            }
            const options = processTypes ? processTypes.map((m) => ({ id: m.ref, name: m.name, siteId: m.siteId })) : [];
            return options;
          }),
          switchMap((processTypeOptions) => {
            return uxData$.pipe(
              map((uxData) => {
                return [
                  ...this.renderResubmitTaskFormFields(formFieldsObj, variables, attachmentItems, uxData),
                  this.jsonEpmWfDataListWfType(processTypeOptions),
                  this.jsonStdWfOutcome(variables['stdWf_outcome'], false, false, [{ id: 'Yes', name: 'EPM.FORM.OUTCOME.COMPLETED' }])
                ];
              })
            );
          })
        );

      default:
        return uxData$.pipe(
          map((uxData) => {
            const formFields = this.renderDefaultTaskFormFields(formFieldsObj, variables, attachmentItems, uxData, true);
            if (uxData.enableAdditionalSpecialist) {
              // add additional specialist field
              formFields.push(this.jsonStdWf2AdditionalSpecialist(null, false));
            }
            return formFields.concat([
              this.jsonStdWfOutcome(
                variables['stdWf_outcome'],
                false,
                false,
                formFieldsObj['stdWf_outcome']?.allowedValues
                  ?.filter((val) => {
                    return !(val === 'AdditionalOpinion' && !uxData.enableAdditionalSpecialist);
                  })
                  .map((opt) => ({
                    id: opt,
                    name: `EPM.FORM.VAR.STDWF.OUTCOME.${opt.toUpperCase()}`
                  }))
              )
            ]);
          })
        );
    }
  }

  protected renderStdWfFormalVerification(uxData$, variables: object, formFieldsObj: object, attachmentItems: Item[]) {
    return uxData$.pipe(
      switchMap((uxData: RetrieveUxDataModel) => {
        return this.legacyContentService
          .getDatalistData(variables['epmWf_dataListWfNode'], ['epmWf_taskIndex', 'epmWf_taskId', 'epmWf_dynamicTaskNameConfig'], {
            filterId: 'all',
            filterData: ''
          })
          .pipe(
            map((dataListsDataModel: DatalistsDataModel) => {
              const options: { id: string; name: string }[] = [
                { id: '', name: '' },
                // { id: '-1', name: this.translation.instant('EPM.FORM.OPTION.END_WORKFLOW') },
                { id: '0', name: this.translation.instant('EPM.FORM.OPTION.REFILL_THE_APPLICATION') }
              ];
              dataListsDataModel?.items?.map((item) => {
                options.push({
                  id: item.itemData['prop_epmWf_taskIndex']?.value.toString(),
                  name: this.getTaskDynamicNameFromDataList(item)
                });
              });
              return this.renderDefaultTaskFormFields(formFieldsObj, variables, attachmentItems, uxData, true).concat([
                this.jsonEpmWfMoveToTaskIndex(0, false, false, options),
                this.jsonEpmWfMoveToOutcome(
                  variables['epmWf_moveToOutcome'],
                  false,
                  false,
                  formFieldsObj['epmWf_moveToOutcome'].allowedValues.map((a) => ({
                    id: a,
                    name: this.translation.instant(`EPM.FORM.OUTCOME.${a.toUpperCase()}`)
                  }))
                )
              ]);
            })
          );
      })
    );
  }

  protected renderDefaultTaskFormFields(
    _formFieldsObj: object,
    variables: object,
    attachmentItems: Item[],
    uxData: RetrieveUxDataModel,
    readOnly: boolean
  ): object[] {
    return [
      this.jsonEpmWfComments(variables['epmWf_comments'], false, true),
      this.jsonEpmWfDataListWfNode(variables['epmWf_dataListWfNode'], false, true),
      this.jsonEpmWfDataListWfTaskNode(variables['epmWf_dataListWfTaskNode'], false, true),
      this.jsonEpmWfDataListWfTypeHidden(variables['epmWf_dataListWfType'], false, true),
      this.jsonEpmWfDataListWfSiteid(variables['epmWf_dataListWfSiteid'], false, true),
      // this.jsonBpmPriority(variables['bpm_workflowPriority'], false, readOnly),
      // this.jsonEpmIssueWfCategory(variables['epmIssueWf_category'], true, true),
      // this.jsonEpmIssueWfExpectedCompletionDate(variables['epmIssueWf_expectedCompletionDate'], true, true),
      this.jsonEpmIssueWfPlanLocationLat(variables['epmIssueWf_planLocationLat'], true, true),
      this.jsonEpmIssueWfPlanLocationLng(variables['epmIssueWf_planLocationLng'], true, true),
      this.jsonEpmIssueWfPlanRef(variables['epmIssueWf_planRef'], false, true),
      this.jsonEpmIssueWfPhotoRefs(variables['epmIssueWf_photoRefs'], false, false, false),
      this.jsonBpmWorkflowDescription(variables['bpm_workflowDescription'], false, true),
      // this.jsonEpmWfDataListWfDesc(variables['epmWf_dataListWfDesc'], false, true),
      ...this.jsonEpmWfDepartment(variables['epmWf_department'], readOnly, uxData),
      this.jsonAssocPackageItemsAdded(
        variables['bpm_package'],
        attachmentItems,
        variables['epmWf_attachmentActionsReadOnly'] === 'NONE' ? AttachmentMode.NONE : AttachmentMode.ADD
      ),
      this.jsonBpmComment(variables['bpm_comment'], false, false)
    ];
  }

  protected renderResubmitTaskFormFields(_formFieldsObj: object, variables: object, attachmentItems: Item[], uxData: RetrieveUxDataModel): object[] {
    return [
      this.jsonEpmWfComments(variables['epmWf_comments'], false, true),
      this.jsonEpmWfDataListWfNode(variables['epmWf_dataListWfNode'], false, true),
      this.jsonEpmWfDataListWfTaskNode(variables['epmWf_dataListWfTaskNode'], false, true),
      // this.jsonEpmWfDataListWfType(variables['epmWf_dataListWfType']),
      this.jsonEpmWfDataListWfSiteid(variables['epmWf_dataListWfSiteid'], false, true),
      // this.jsonBpmPriority(variables['bpm_workflowPriority'], false, false),
      // this.jsonEpmIssueWfCategory(variables['epmIssueWf_category'], true, false),
      // this.jsonEpmIssueWfExpectedCompletionDate(variables['epmIssueWf_expectedCompletionDate'], true, false),
      this.jsonEpmIssueWfPlanLocationLat(variables['epmIssueWf_planLocationLat'], true, false),
      this.jsonEpmIssueWfPlanLocationLng(variables['epmIssueWf_planLocationLng'], true, false),
      this.jsonEpmIssueWfPlanRef(variables['epmIssueWf_planRef'], false, false),
      this.jsonEpmIssueWfPhotoRefs(variables['epmIssueWf_photoRefs'], false, false, false),
      this.jsonBpmWorkflowDescription(variables['bpm_workflowDescription'], false, false),
      // this.jsonEpmWfDataListWfDesc(variables['epmWf_dataListWfDesc'], false, false),
      ...this.jsonEpmWfDepartment(variables['epmWf_department'], false, uxData),
      this.jsonAssocPackageItemsAdded(
        variables['bpm_package'],
        attachmentItems,
        variables['epmWf_attachmentActionsReadOnly'] === 'NONE' ? AttachmentMode.NONE : AttachmentMode.ADD
      ),
      this.jsonBpmComment(variables['bpm_comment'], false, false)
    ];
  }

  // private jsonEpmIssueWfCategory(value: any, required: boolean, readOnly: boolean) {
  //   return createFormFieldContainerJson(
  //     'epmIssueWf_category',
  //     this.translation.instant('EPM.FORM.VAR.EPMISSUEWF.CATEGORY'),
  //     'epm-dropdown',
  //     value,
  //     required,
  //     this.processDynamicFormData.restFormModel
  //       ?.find((fieldModel) => fieldModel.name === 'epmIssueWf_category')
  //       ?.allowedValues?.map((item) => ({
  //         id: item,
  //         name: this.translation.instant(`EPM.FORM.VAR.EPMISSUEWF.CATEGORY_OPTIONS.${item.toUpperCase()}`)
  //       })),
  //     readOnly
  //   );
  // }

  private jsonEpmIssueWfPlanLocationLat(value: any, required: boolean, readOnly: boolean) {
    return createFormFieldContainerJson('epmIssueWf_planLocationLat', '', 'hidden-input', value, required, null, readOnly);
  }

  private jsonEpmIssueWfPlanLocationLng(value: any, required: boolean, readOnly: boolean) {
    return createFormFieldContainerJson('epmIssueWf_planLocationLng', '', 'hidden-input', value, required, null, readOnly);
  }

  // private jsonEpmIssueWfExpectedCompletionDate(value: any, required: boolean, readOnly: boolean) {
  //   return createFormFieldContainerJson(
  //     'epmIssueWf_expectedCompletionDate',
  //     this.translation.instant('EPM.FORM.VAR.EPMISSUEWF.EXPECTEDCOMPLETIONDATE'),
  //     'date',
  //     value,
  //     required,
  //     null,
  //     readOnly
  //   );
  // }

  private jsonEpmIssueWfPlanRef(value: any, required: boolean, readOnly: boolean) {
    return createFormFieldContainerJson(
      'epmIssueWf_planRef',
      this.translation.instant('EPM.FORM.VAR.EPMISSUEWF.PLANREF'),
      'plan',
      value,
      required,
      null,
      readOnly
    );
  }

  private jsonEpmIssueWfPhotoRefs(value: any, required: boolean, readOnly: boolean, allowDelete: boolean) {
    return createFormFieldContainerJson(
      'epmIssueWf_photoRefs',
      this.translation.instant('EPM.FORM.VAR.EPMISSUEWF.PHOTOREFS'),
      'epm-photo-upload',
      value,
      required,
      [
        {
          id: 'allowDelete',
          name: allowDelete.toString()
        }
      ],
      readOnly
    );
  }
}
