import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormFieldEvent, FormFieldModel, FormRulesEvent, FormService } from '@alfresco/adf-core';
import { QueryApiService } from '../../../../services/query-api.service';
import { ProcessDynamicFormData } from '../../../../services/process-dynamic-form.data';
import { Person } from '@alfresco/js-api';
import { EpmMultiplePeopleWidgetComponent } from '../epm-multiple-people/epm-multiple-people-widget.component';

@Component({
  selector: 'epm-stdwf2-additional-specialist',
  templateUrl: '../epm-multiple-people/epm-multiple-people-widget.component.html',
  styleUrls: ['../epm-multiple-people/epm-multiple-people-widget.component.scss'],
  host: {
    '(click)': 'event($event)',
    '(blur)': 'event($event)',
    '(change)': 'event($event)',
    '(focus)': 'event($event)',
    '(focusin)': 'event($event)',
    '(focusout)': 'event($event)',
    '(input)': 'event($event)',
    '(invalid)': 'event($event)',
    '(select)': 'event($event)'
  },
  encapsulation: ViewEncapsulation.None
})
export class Stdwf2AdditionalSpecialistWidgetComponent extends EpmMultiplePeopleWidgetComponent implements OnInit {
  private readonly ADDITIONAL_SPECIALIST_BUTTON_ID = 'AdditionalOpinion';

  constructor(public formService: FormService, queryService: QueryApiService, private processDynamicFormData: ProcessDynamicFormData) {
    super(formService, queryService);
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.field.value) {
      this.processDynamicFormData.changeButtonState.next({ buttonId: this.ADDITIONAL_SPECIALIST_BUTTON_ID, state: 'disabled' });
    }
  }

  onItemSelect(item?: Person) {
    if (item) {
      this.selectedPeople.push(item);
      this.field.value = this.selectedPeople.map((user) => user.id);
      this.inputValue.nativeElement.value = '';
    }
    const obj: { buttonId: string; state: 'enabled' | 'disabled' } = { buttonId: this.ADDITIONAL_SPECIALIST_BUTTON_ID, state: 'disabled' };
    if (item) {
      obj.state = 'enabled';
    }
    this.processDynamicFormData.changeButtonState.next(obj);
  }

  onFieldChanged(field: FormFieldModel) {
    field.value = [this.field.value];
    this.fieldChanged.emit(field);
    this.formService.formRulesEvent.next(new FormRulesEvent('fieldValueChanged', new FormFieldEvent(this.field?.form, this.field), null));
  }
}
