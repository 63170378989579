import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessService } from '../../../services/process.service';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { ProcessInstanceModel } from '../../../model/process-instance-model';
import { TaskStatus } from '../../../api/services/workflow-api.service';
import { TaskInstance } from '../../../api/models/task/task-instance';
import {
  CardViewDateItemModel,
  CardViewItem,
  CardViewSelectItemModel,
  CardViewTextItemModel,
  ContentLinkModel,
  DataRowEvent,
  FormFieldModel,
  TranslationService
} from '@alfresco/adf-core';
import { DomSanitizer } from '@angular/platform-browser';
import { extractNodeId } from '../../../api/utils/node';
import { ContentApiService } from '@alfresco/aca-shared';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Node } from '@alfresco/js-api';
import { AppStore, DownloadNodesAction, SnackbarInfoAction, ViewNodeAction } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { map, take, takeUntil } from 'rxjs/operators';
import { CardViewSelectItemOption } from '@alfresco/adf-core/lib/card-view/interfaces/card-view-selectitem-properties.interface';
import { EpmIssueModel } from '../../../api/models/legacy/epm-issue-issue.model';
import * as Leaflet from 'leaflet';
import { LeafletIcons } from '../../../model/leaflet-color-markers';
import { PlanService } from '../../form/services/plan.service';

@Component({
  selector: 'aca-process-details',
  templateUrl: './process-details.component.html',
  styleUrls: ['./process-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProcessDetailsComponent implements OnInit, OnDestroy {
  isValidId = true;
  processInstanceId: string;
  processInstanceModel: ProcessInstanceModel;
  mainPropertiesItems: CardViewItem[] = [];
  secondaryPropertiesItems: CardViewItem[] = [];
  activeTasksData: ActiveTasksData[] = null;
  historicTasksData: HistoricTasksData[] = null;
  attachmentNodes: Node[] = null;
  diagramImg: any;
  simpleDiagramImg: any;
  issueWfPhotoUrls: [];
  issueWfPhotos: { url: string; taskName: string }[];
  issueWfPlanUrl: string;
  issueWfPlanMarker: { issue: EpmIssueModel; icon: Leaflet.Icon };

  dummyAttachmentField = new FormFieldModel(null, {
    readOnly: true,
    required: false,
    type: 'upload',
    overrideId: false,
    id: 'dummy-attachment-field',
    name: 'Dummy attachment field',
    params: {
      attachmentItems: [],
      displayableCMProperties: [],
      existingColspan: 1,
      fileSource: {
        name: 'All fiel sources',
        serviceId: 'all-file-sources'
      },
      link: true,
      maxColspan: 2,
      menuOptions: {
        show: true,
        download: true,
        retrieveMetadata: false,
        remove: false
      },
      multiple: 'multiple'
    }
  });

  private readonly onDestroy$ = new Subject<boolean>();
  private displayUserName: string;
  private siteName: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private processService: ProcessService,
    private contentApi: ContentApiService,
    private translation: TranslationService,
    private domSanitizer: DomSanitizer,
    private store: Store<AppStore>,
    private planService: PlanService
  ) {}

  ngOnInit(): void {
    // info: Router will auto unsubscribe this subscription
    this.route.params.subscribe((params) => {
      this.reset();
      this.processInstanceId = params['id'];
      this.load();
    });
  }

  reset() {
    this.isValidId = true;
    this.mainPropertiesItems = [];
    this.secondaryPropertiesItems = [];
    this.processInstanceModel = null;
    this.attachmentNodes = null;
    this.historicTasksData = null;
    this.activeTasksData = null;
    this.diagramImg = null;
    this.simpleDiagramImg = null;
  }

  load() {
    if (this.processInstanceId) {
      forkJoin({
        processInstance: this.processService.getProcessInstance(this.processInstanceId),
        processVariables: this.processService.getProcessInstanceVariables(this.processInstanceId)
      })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (res) => {
            res.processInstance.processVariables = res.processVariables;
            this.processInstanceModel = new ProcessInstanceModel(res.processInstance);
            this._fetchAdditionalData();
          },
          () => (this.isValidId = false)
        );
    }
    this._fetchActiveTasksData();
  }

  goBack() {
    const plan = this.planService.selectedPlan$.getValue();
    if (plan) {
      this.router.navigate(['epm', 'plan']);
    } else {
      this.router.navigate(['epm', 'processes']);
    }
  }

  onTaskRowClick(item: DataRowEvent) {
    this.router.navigate(['/epm/task', item.value.getValue('id'), 'view']);
  }

  onSelectedTabChange(event: MatTabChangeEvent) {
    // items are lazy loaded - only when users switches a tab
    switch (event.index) {
      case 0:
        // active tasks are fetched on load
        // this._fetchActiveTasksData();
        break;
      case 1:
        // properties are fetched on load
        break;
      case 2:
        if (!this.hasIssueWfPhotos()) {
          this._fetchHistoricTasksData();
        }
        break;
      case 3:
        if (this.hasIssueWfPhotos()) {
          this._fetchHistoricTasksData();
        } else {
          this._fetchAttachments();
        }
        break;
      case 4:
        if (this.hasIssueWfPhotos()) {
          this._fetchAttachments();
        } else {
          this._fetchSimpleDiagram();
        }
        break;
      case 5:
        // plan issue tab
        break;
      case 6:
        // issue photos tab, URLS are fetched on init
        break;
    }
  }

  onAttachmentClicked(link: ContentLinkModel) {
    // view the attachment
    this.store.dispatch(new ViewNodeAction(link.id.toString(), { location: this.router.url }));
  }

  onDownloadAttachment(file: Node) {
    // download the attachment
    const node: Node = new Node({ name: file.name, id: file.id });
    const nodeEntry = { entry: node };
    this.store.dispatch(new DownloadNodesAction([nodeEntry], {}));
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  private _setCardViewMainProperties() {
    this.mainPropertiesItems = [
      new CardViewTextItemModel({
        label: this.translation.instant('EPM.FORM.VAR.EPMWF.DATALISTWFTYPE'),
        key: 'type',
        editable: false,
        value: this.processInstanceModel.variables['epmWf_dataListWfType']
      }),
      new CardViewTextItemModel({
        label: this.translation.instant('EPM.START_PROCESS.FORM.LABEL.SITE'),
        key: 'site',
        editable: false,
        value: this.siteName ? this.siteName : this.processInstanceModel.variables['epmWf_dataListWfSiteid']
      }),
      // new CardViewTextItemModel({
      //   label: this.translation.instant('EPM.FORM.VAR.BPM.DESCRIPTION'),
      //   key: 'desc',
      //   editable: false,
      //   value: this.processInstanceModel.variables['bpm_description']
      // }),
      new CardViewSelectItemModel({
        label: this.translation.instant('EPM.FORM.VAR.BPM.WORKFLOWPRIORITY'),
        key: 'priority',
        editable: false,
        displayNoneOption: false,
        value: this.processInstanceModel.variables['bpm_workflowPriority'],
        options$: of(this._getPriorityOptions())
      }),
      new CardViewTextItemModel({
        label: this.translation.instant('EPM.FORM.VAR.STARTEDBY'),
        key: 'startedBy',
        editable: false,
        value: this.displayUserName ? this.displayUserName : this.processInstanceModel.startUserId
      }),
      new CardViewDateItemModel({
        label: this.translation.instant('EPM.FORM.VAR.START_TASK_COMPLETED'),
        key: '_startTaskCompleted',
        editable: false,
        value: this.processInstanceModel.variables['_startTaskCompleted'],
        format: 'mediumDate'
      }),
      new CardViewDateItemModel({
        label: this.translation.instant('EPM.FORM.VAR.BPM.WORKFLOWDUEDATE'),
        key: 'workflowDueDate',
        editable: false,
        value: this.processInstanceModel.variables['bpm_workflowDueDate'],
        format: 'mediumDate'
      }),
      new CardViewTextItemModel({
        label: this.translation.instant('EPM.FORM.VAR.EPMWF.DEPARTMENT'),
        key: 'department',
        editable: false,
        value: this._getTranslatedWfDepartment()
      }),
      new CardViewTextItemModel({
        label: this.translation.instant('EPM.FORM.VAR.BPM.COMMENT'),
        key: 'department', // ?

        editable: false,
        multiline: true,
        value: this.getComment()
      })
    ];

    if (this._isIssueWorkflow()) {
      this.mainPropertiesItems.push(
        new CardViewSelectItemModel({
          label: this.translation.instant('EPM.FORM.VAR.EPMISSUEWF.CATEGORY'),
          key: 'epmIssueWf_category',
          editable: false,
          displayNoneOption: false,
          value: this.processInstanceModel.variables['epmIssueWf_category'],
          options$: of(this._getIssueWfCategoryOptions())
        })
      );
    }

    this._initPhotoUrls();
  }

  private _getTranslatedWfDepartment() {
    const departments = this.processInstanceModel.variables['epmWf_department'];
    if (!departments) {
      return '';
    }
    const translatedDepartments = [];
    const departmentsArray = departments && !Array.isArray(departments) ? departments.split(',') : departments;
    for (let department of departmentsArray) {
      let translatedVal = this.translation.instant('EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.' + department.toUpperCase());
      if (translatedVal.startsWith('EPM.FORM.VAR.EPMWF.')) {
        translatedVal = department;
      }
      translatedDepartments.push(translatedVal);
    }
    return translatedDepartments.join(', ');
  }
  getComment() {
    let commentFromServer = this.processInstanceModel.variables['epmWf_comments'];

    if (Array.isArray(commentFromServer) && commentFromServer.length > 0) {
      let comment = commentFromServer[0];

      if (typeof comment === 'string') {
        let parts = comment.split(':');

        if (parts.length > 2) {
          let middlePart = parts[2].trim();
          middlePart = middlePart.replace(/`/g, ',').replace(/~/g, ':');

          return middlePart;
        }
      }
    }
    return '';
  }

  private _getIssueWfCategoryOptions() {
    return [
      { key: 'notApplicable', label: this.translation.instant('EPM.FORM.VAR.EPMISSUEWF.CATEGORY_OPTIONS.NOTAPPLICABLE') },
      { key: 'acceptance', label: this.translation.instant('EPM.FORM.VAR.EPMISSUEWF.CATEGORY_OPTIONS.ACCEPTANCE') },
      { key: 'defect', label: this.translation.instant('EPM.FORM.VAR.EPMISSUEWF.CATEGORY_OPTIONS.DEFECT') },
      { key: 'fault', label: this.translation.instant('EPM.FORM.VAR.EPMISSUEWF.CATEGORY_OPTIONS.FAULT') }
    ];
  }

  private _getPriorityOptions(): CardViewSelectItemOption<string>[] {
    return [
      { key: '1', label: this.translation.instant('EPM.FORM.PRIORITY.1') },
      { key: '2', label: this.translation.instant('EPM.FORM.PRIORITY.2') },
      { key: '3', label: this.translation.instant('EPM.FORM.PRIORITY.3') }
    ];
  }

  private _initPhotoUrls() {
    if (this.hasIssueWfPhotos()) {
      const photoRefs = this.processInstanceModel.variables['epmIssueWf_photoRefs'];
      if (photoRefs) {
        const photoRefsArr = Array.isArray(photoRefs) ? photoRefs : photoRefs.split(',');
        const observables = photoRefsArr.map((id) => this.contentApi.getNodeInfo(extractNodeId(id)));
        forkJoin(observables)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((nodes) => {
            console.log('nodes', nodes);
            this.issueWfPhotos = nodes.map((node: any) => {
              return {
                url: this.contentApi.getContentUrl(node.id),
                taskName: node.properties['epmIssue:photoTask']
              };
            });
          });
        this.issueWfPhotoUrls = photoRefsArr.map((id) => this.contentApi.getContentUrl(extractNodeId(id)));
      } else {
        this.issueWfPhotoUrls = [];
      }
    }
  }

  private _fetchUserDisplayName(userId: string): Observable<string> {
    return this.contentApi.getPerson(userId).pipe(
      take(1),
      map((userInfo) => (userInfo?.entry?.displayName ? userInfo.entry.displayName : userId))
    );
  }

  private _fetchSiteName(siteId: string): Observable<string> {
    return this.contentApi.getSite(siteId).pipe(
      take(1),
      map((siteInfo) => (siteInfo?.entry?.title ? siteInfo.entry.title : siteId))
    );
  }

  private _fetchAdditionalData() {
    forkJoin([
      this._fetchUserDisplayName(this.processInstanceModel.startUserId),
      this._fetchSiteName(this.processInstanceModel.variables['epmWf_dataListWfSiteid'])
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([disaplayUserName, siteName]) => {
        this.displayUserName = disaplayUserName;
        this.siteName = siteName;
        this._setCardViewMainProperties();
        this._setupWfIssuePlanAndMarker();
      });
  }

  private _setupWfIssuePlanAndMarker() {
    if (this.hasIssueWfPlan()) {
      const planNodeRef = this.processInstanceModel.variables['epmIssueWf_planRef'];
      this.issueWfPlanUrl = this.contentApi.getContentUrl(extractNodeId(planNodeRef));
      const wfIssue = new EpmIssueModel({
        name: '',
        locationLat: this.processInstanceModel.variables['epmIssueWf_planLocationLat'],
        locationLng: this.processInstanceModel.variables['epmIssueWf_planLocationLng']
      });
      this.issueWfPlanMarker = {
        issue: wfIssue,
        icon: LeafletIcons[0].icon
      };
    }
  }

  private _fetchActiveTasksData() {
    if (!this.activeTasksData) {
      this.processService
        .getProcessInstanceTasks(this.processInstanceId, TaskStatus.ACTIVE)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((tasks: TaskInstance[]) => {
          const data = [];
          this.activeTasksData = [];
          for (let task of tasks) {
            data.push({
              id: task.id,
              type: task.name,
              assignedTo: Array.isArray(task.candidates) ? task.candidates.join(', ') : task.candidates,
              dueDate: task.dueAt
            });
          }
          this.activeTasksData = data;
        });
    }
  }

  private _fetchHistoricTasksData() {
    if (!this.historicTasksData) {
      this.processService
        .getProcessInstanceTasks(this.processInstanceId, TaskStatus.COMPLETED, ['startedAt', 'DESC'])
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((tasks: TaskInstance[]) => {
          const data = [];
          this.historicTasksData = [];
          for (let task of tasks) {
            data.push({
              id: task.id,
              type: task.name,
              completedBy: task.assignee,
              dateCompleted: task.endedAt,
              outcome: '',
              comment: ''
            });
          }
          this.historicTasksData = data;

          // load outcome and comment
          const taskIds = tasks.map((t) => t.id);
          this._getTasksForEachProcess(taskIds).subscribe((taskArray) => {
            taskArray.forEach((taskVariables, idx) => {
              const dataTask = this.historicTasksData[idx];
              dataTask.comment = taskVariables['bpm_comment'];
              const outcomePropName = taskVariables['bpm_outcomePropertyName'];
              if (outcomePropName) {
                dataTask.outcome = taskVariables[outcomePropName.replace(':', '_')];
                if (dataTask.outcome) {
                  const tryTranslate: string = this.translation.instant('EPM.FORM.VAR.STDWF.OUTCOME.' + dataTask.outcome.toUpperCase());
                  if (!tryTranslate.startsWith('EPM.FORM.OUTCOME')) {
                    dataTask.outcome = tryTranslate;
                  }
                }
              }
            });
            const old = this.historicTasksData;
            this.historicTasksData = [];
            this.historicTasksData.push(...old);
          });
        });
    }
  }

  private _getTasksForEachProcess(taskIds: string[]): Observable<any> {
    let taskObservables: Observable<{}>[] = taskIds.map((id: string) => this.processService.getTaskVariables(id));
    return forkJoin(taskObservables).pipe(takeUntil(this.onDestroy$));
  }

  private _fetchAttachments() {
    if (!this.attachmentNodes) {
      const attachmentListNodes: string[] = this.processInstanceModel.variables['attachmentsList'];
      if (attachmentListNodes && attachmentListNodes.length !== 0) {
        const nodesInfo$: Observable<any>[] = [];
        attachmentListNodes.forEach((nodeRef: string) => {
          const nodeRefID = extractNodeId(nodeRef);
          nodesInfo$.push(this.contentApi.getNodeInfo(nodeRefID));
        });
        forkJoin(nodesInfo$)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            (items: Node[]) => {
              this.attachmentNodes = [];
              items.forEach((node) => {
                this.attachmentNodes.push(node);
              });
            },
            (error) => {
              let errorMsg = 'Could not retrieve attachments. You may be not allowed to view them.';
              if (error && error.statusCode == 403) {
                errorMsg = 'You are not allowed to view attachments.';
              }
              this.store.dispatch(new SnackbarInfoAction(errorMsg));
            }
          );
      }
    }
  }
  private _fetchSimpleDiagram() {
    if (!this.simpleDiagramImg && this.processInstanceModel?.processDefinitionId) {
      this.processService
        .getProcessDefinitionSimpleImage(this.processInstanceModel.id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((data) => {
          this.simpleDiagramImg = this.domSanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(data));
        });
    }
  }

  private _isIssueWorkflow(): boolean {
    return this.processInstanceModel && this.processInstanceModel['processDefinitionKey'] === 'issueWorkflow';
  }

  hasIssueWfPhotos(): boolean {
    if (this.processInstanceModel?.variables['epmIssueWf_photoRefs'] && this.processInstanceModel?.variables['epmIssueWf_photoRefs'].length > 0) {
      return true;
    } else {
      return false;
    }
  }

  hasIssueWfPlan(): boolean {
    return !!this.processInstanceModel?.variables['epmIssueWf_planRef'];
  }
}

interface ActiveTasksData {
  type: string;
  assignedTo: string;
  dueDate: Date;
}

interface HistoricTasksData {
  id: string;
  type: string;
  completedBy: string;
  dateCompleted: Date;
  outcome: string;
  comment: string;
}
