<div class="epmwf-comments-widget {{field.className}}"
     [class.adf-invalid]="!field.isValid && isTouched()" [class.adf-readonly]="field.readOnly">
  <label class="adf-label" [attr.for]="field.id">{{field.name | translate }}</label>
  <adf-datatable
    [data]="data">
    <data-columns>
      <data-column key="completedBy" [class]="'adf-no-grow-cell'"></data-column>
      <!-- <data-column key="taskType"></data-column> -->
      <data-column key="comment">
        <ng-template let-context="$implicit">
          <span [innerHTML]="context.row.getValue('comment')"></span>
        </ng-template>
      </data-column>
      <data-column key="completionDate" type="text" [class]="'adf-no-grow-cell'"></data-column>
      <!-- <data-column key="outcome"></data-column> -->
    </data-columns>
  </adf-datatable>
</div>
