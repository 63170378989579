import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule, MaterialModule, MOMENT_DATE_FORMATS, TranslationService } from '@alfresco/adf-core';
import { ExtensionService, provideExtensionConfig } from '@alfresco/adf-extensions';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { GenericErrorModule, PageLayoutModule, SharedDirectivesModule, SharedModule } from '@alfresco/aca-shared';
import { ContentModule } from '@alfresco/adf-content-services';
import { CommonModule } from '@angular/common';
import { PeopleSearchComponent } from './components/people-search/people-search.component';
import { A11yModule } from '@angular/cdk/a11y';
import { ProcessNewComponent } from './components/process/process-new/process-new.component';
import { AttachFileWidgetComponentComponent } from './components/form/widgets/attach-file/attach-file-widget-component.component';
import { UploadWidgetComponentComponent } from './components/form/widgets/attach-file/upload-widget-component.component';
import { SelectedFileViewComponent } from './components/form/widgets/attach-file/selected-file-view/selected-file-view.component';
import { DropdownWidgetComponent } from './components/form/widgets/dropdown/dropdown-widget.component';
import { MultiCheckboxComponent } from './components/form/widgets/multi-checkbox/multi-checkbox.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatetimeAdapter, MAT_DATETIME_FORMATS } from '@mat-datetimepicker/core';
import { MAT_MOMENT_DATETIME_FORMATS, MomentDatetimeAdapter } from '@mat-datetimepicker/moment';
import { ProcessDetailsComponent } from './components/process/process-details/process-details.component';
import { TaskEditComponent } from './components/task/task-edit/task-edit.component';
import { EpmwfCommentsComponent } from './components/form/widgets/epmwf-comments/epmwf-comments.component';
import { DlTypeComponent } from './components/form/widgets/dl-type/dl-type.component';
import { DlTypeValidator } from './components/form/widgets/dl-type/dl-type.validator';
import { HiddenInputComponent } from './components/form/widgets/hidden-input/hidden-input.component';
import { OutcomesComponent } from './components/form/widgets/outcomes/outcomes.component';
import { TasksComponent } from './components/task/tasks/tasks.component';
import { TasksListComponent } from './components/task/tasks/tasks-list/tasks-list.component';
import { ProcessesListComponent } from './components/process/processes/processes-list/processes-list.component';
import { ProcessesComponent } from './components/process/processes/processes.component';
import { TasksFilterComponent } from './components/task/tasks/tasks-filter/tasks-filter.component';
import { ProcessesFilterComponent } from './components/process/processes/processes-filter/processes-filter.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { PlanComponent } from './components/plan/plan.component';
import { StdWf2CompliantMaterialComponent } from './components/form/widgets/stdwf2-compliant-material/std-wf2-compliant-material.component';
import { EpmPeopleWidgetComponent } from './components/form/widgets/epm-people/epm-people-widget.component';
import { Stdwf2AdditionalSpecialistWidgetComponent } from './components/form/widgets/stdwf2-additional-specialist/stdwf2-additional-specialist-widget.component';
import { EpmMultiplePeopleWidgetComponent } from './components/form/widgets/epm-multiple-people/epm-multiple-people-widget.component';
import { PlanWidgetComponent } from './components/form/widgets/plan-widget/plan-widget.component';
import { PlanMapComponent } from './components/form/widgets/plan-widget/plan-map/plan-map.component';
import { StartProcessActionComponent } from './components/actions/start-process-action/start-process-action.component';
import { PhotoUploadWidgetComponent } from './components/form/widgets/photo-upload/photo-upload-widget.component';
import * as epmRules from './epm-workflow.rules';
import { PlanFilterComponent } from './components/plan/plan-filter/plan-filter.component';
// import { EpmContentNodeSelectorComponent } from './components/form/widgets/epm-content-node-selector/epm-content-node-selector.component';
// import { EpmContentNodeSelectorPanelComponent } from './components/form/widgets/epm-content-node-selector/epm-content-node-selector-panel.component';
import { EpmTextDatalistWidgetComponent } from './components/form/widgets/text-datalist/text-datalist.component';

export function components() {
  return [
    TasksComponent,
    ProcessesListComponent,
    ProcessesComponent,
    ProcessesFilterComponent,
    ProcessDetailsComponent,
    PeopleSearchComponent,
    ProcessNewComponent,
    AttachFileWidgetComponentComponent,
    UploadWidgetComponentComponent,
    SelectedFileViewComponent,
    DropdownWidgetComponent,
    MultiCheckboxComponent,
    TasksFilterComponent,
    TasksListComponent,
    TaskEditComponent,
    EpmwfCommentsComponent,
    DlTypeComponent,
    DlTypeValidator,
    HiddenInputComponent,
    OutcomesComponent,
    PlanComponent,
    StdWf2CompliantMaterialComponent,
    EpmPeopleWidgetComponent,
    EpmMultiplePeopleWidgetComponent,
    Stdwf2AdditionalSpecialistWidgetComponent,
    PlanWidgetComponent,
    PlanMapComponent,
    StartProcessActionComponent,
    PhotoUploadWidgetComponent,
    PlanFilterComponent,
    // EpmContentNodeSelectorComponent,
    // EpmContentNodeSelectorPanelComponent,
    EpmTextDatalistWidgetComponent
  ];
}
@NgModule({
  imports: [
    CommonModule,
    CoreModule.forChild(),
    SharedModule,
    PageLayoutModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    FlexModule,
    ContentModule,
    SharedDirectivesModule,
    A11yModule,
    FlexLayoutModule,
    GenericErrorModule,
    LeafletModule
  ],
  providers: [
    provideExtensionConfig(['epm-workflow-extension.json']),
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MOMENT_DATE_FORMATS },
    { provide: DatetimeAdapter, useClass: MomentDatetimeAdapter },
    { provide: MAT_DATETIME_FORMATS, useValue: MAT_MOMENT_DATETIME_FORMATS }
  ],
  declarations: components(),
  exports: components()
})
export class EpmWorkflowExtensionModule {
  constructor(extensions: ExtensionService, translation: TranslationService) {
    translation.addTranslationFolder('epm-workflow-extension', 'assets/epm-workflow-extension');

    extensions.setComponents({
      'epm-workflow.processes': ProcessesComponent,
      'epm-workflow.tasks': TasksComponent,
      'epm-workflow.task-edit': TaskEditComponent,
      'epm-workflow.process-new': ProcessNewComponent,
      'epm-workflow.process-details': ProcessDetailsComponent,
      'epm-workflow.plan': PlanComponent,
      'epm-workflow.toolbar.start-process': StartProcessActionComponent
    });

    extensions.setEvaluators({
      'epm.isStartWorkflowVisible': epmRules.isStartWorkflowVisible,
      'epm.canCreateLibrary': epmRules.canCreateLibrary
    });
  }
}
