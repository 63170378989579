<div class="epm-people-widget {{field.className}}"
     [class.adf-invalid]="!field.isValid && isTouched()"
     [class.adf-readonly]="field.readOnly"
     id="people-widget-content">
  <mat-form-field>
    <label class="adf-label" [attr.for]="field.id">{{field.name | translate }}<span class="adf-asterisk" *ngIf="isRequired()">*</span></label>
    <mat-chip-list #peopleList>
      <mat-chip *ngFor="let user of field.value" (removed)="remove(user)">
        {{user}}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input #inputValue
             matInput
             class="adf-input"
             data-automation-id="epm-people-search-input"
             type="text"
             [id]="field.id"
             [formControl]="searchTerm"
             [placeholder]="field.placeholder"
             [matAutocomplete]="auto"
             [matChipInputFor]="peopleList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             (blur)="markAsTouched()"
             [matTooltip]="field.tooltip"
             matTooltipPosition="above"
             matTooltipShowDelay="1000">
    </mat-chip-list>
    <mat-autocomplete class="epm-people-widget-list"
                      #auto="matAutocomplete"
                      (optionSelected)="onItemSelect($event.option.value)"
                      [displayWith]="getDisplayName">
      <mat-option *ngFor="let user of users$ | async; let i = index" [value]="user">
        <div class="epm-people-widget-row" id="epm-people-widget-user-{{i}}">
          <div [outerHTML]="user | usernameInitials:'epm-people-widget-pic'"></div>
          <span class="epm-people-label-name">{{getDisplayName(user)}}</span>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <error-widget [error]="field.validationSummary"></error-widget>
  <error-widget *ngIf="isInvalidFieldRequired() && isTouched()" required="{{ 'FORM.FIELD.REQUIRED' | translate }}"></error-widget>
</div>
