<div class="outcomes-widget {{field.className}}">
  <label *ngIf="!(isResubmit$ | async)" class="adf-label" [attr.for]="field.id">{{field.name | translate }}<span class="adf-asterisk" *ngIf="isRequired()">*</span></label>
  <br>
  <ng-container *ngFor="let outcome of field.options">
    <ng-container *ngIf="outcome.id === 'moveto'">
      <button [id]="'aca-form-outcome-' + outcome.name | formatSpace"
            mat-raised-button
            color="accent"
            class="outcome-button"
            [matMenuTriggerFor]="moveToMenu"
            [disabled]="isButtonDisabled(outcome.id)"
            >
            <mat-menu #moveToMenu>
              <button
                mat-menu-item
                *ngFor="let option of moveToTaskOptions"
                (click)="selectMoveToTask(outcome, option.id)">
                {{option.name}}
              </button>
            </mat-menu>
      {{outcome.name | translate | uppercase}}
      </button>
    </ng-container>
    <ng-container *ngIf="outcome.id !== 'moveto'">
      <button [id]="'aca-form-outcome-' + outcome.name | formatSpace"
              mat-raised-button
              color="accent"
              class="outcome-button"
              [disabled]="isButtonDisabled(outcome.id)"
              (click)="onOutcomeClicked(outcome)">
        {{outcome.name | translate | uppercase}}
      </button>
    </ng-container>
  </ng-container>
</div>
